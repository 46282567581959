/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import nl from 'date-fns/locale/nl';

import Layout, { Seo } from '../../Layout';
import Cover from '../../Elements/Cover';
import PageSection from '../../Elements/PageSection';
import NewsLink from './components/NewsLink';
import withTranslations from '../../../i18n/withTranslations';

const Article = ({
  pageContext: {
    article, prevArticle, nextArticle, canonical,
  },
}) => {
  const { t } = useTranslation('componentNieuws');

  // Get the current article.
  const parsed = new Date(article.date.replace(' ', 'T'));
  const date = format(parsed, 'EEEE, dd MMMM yyyy', { locale: nl });

  // Get the author.
  const author = article.author !== '' ? article.author : 'Exonet';

  const prev = prevArticle || false;
  const next = nextArticle || false;

  return (
    <Layout className="news">
      <Seo title={`${t('seoTitle')} - ${article.title}`}>
        {canonical !== '' && <link rel="canonical" href={canonical} />}
      </Seo>
      <Cover>
        <PageSection top center>
          <h1>{t('title')}</h1>
          <div className="article">
            <div className="header">
              <h2>{article.title}</h2>
              <p className="details">{`Gepubliceerd op ${date} door ${author}`}</p>
            </div>
            <div className="body" dangerouslySetInnerHTML={{ __html: article.body_html }} />
            <div className="nav">
              {prev !== false && <NewsLink article={prev} />}
              {next !== false && <NewsLink next article={next} />}
            </div>
          </div>
        </PageSection>
      </Cover>
    </Layout>
  );
};

const articleType = PropTypes.shape({
  date: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  author: PropTypes.string,
  body_html: PropTypes.string,
});

Article.propTypes = {
  pageContext: PropTypes.shape({
    article: articleType,
    prevArticle: PropTypes.oneOfType([articleType, PropTypes.bool]),
    nextArticle: PropTypes.oneOfType([articleType, PropTypes.bool]),
    canonical: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
};

export default withTranslations(Article);
